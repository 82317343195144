.faq-nav {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  li+li {
    margin-left: 15px;
  }
  button {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid $primaryColor;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    background: transparent;
    cursor: pointer;
    color: #fff;
    border-radius: 4px;
    transition: all .2s ease;

    &.selected {
      background: $primaryColor;
      color: #000;
    }
  }
}

.faq-item-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding-top: 30px;
  &__item {
    width: calc(100% / 3);
    padding: 0 10px;
    padding-bottom: 20px;
  }
}
.faq-item {
  &__question {
    font-size: 16px;
    margin: 0;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__answer {
    font-size: 12px;
    margin: 0;
    line-height: 1.5;
    font-style: italic;
    opacity: .5;
  }
}