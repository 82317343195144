.news-img {
  width: 450px;
  margin: 0 auto;
  max-width: 95%;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.news-grid-section {
  padding: 50px 0;
  margin: 30px 0;
  margin-bottom: 0;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;

  @include media('<1024px') {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.news-preview {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  overflow: hidden;
  background: $primaryColorHover;
  color: #000;
  text-align: center;
  padding-bottom: 15px;
  transition: all .2s ease;

  @include media('<1024px') {
    font-size: 12px;
  }

  &__img {
    height: 230px;
    width: 100%;

    @include media('<1024px') {
      height: 200px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 18px;
    text-align: center;
    font-family: $titleFont;
    text-transform: uppercase;
    padding: 0 15px;
    padding-top: 15px;

    @include media('<1024px') {
      font-size: 16px;
    }
  }

  &:hover {
    color: #000;
    opacity: .85;
  }
}

.news-section {
  padding-bottom: 0;
}
.news-content {
  background: $darkBox;
  border-radius: 20px;
  overflow: hidden;

  &__content {
    padding: 20px;
  }

  &__img {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    @include media('<1024px') {
      height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 18px;
    color: #fff;
  }

  &__text {
    opacity: .85;
    color: #fff;
  }
}
