@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";
@import "../assets/styles/main";

body {
  background: $main_bg;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body.modal-opened {
  overflow: hidden;
}

.landing-pages {
  font-family: "Inter-Regular", sans-serif;
  background: $dark;
  color: #fff;
  
  h1,h2,h3,h4,h5,h6 {
    color: #fff;
    font-family: $mainSansSerifBold;
  }
  .ant-typography {
    color: #fff;
  }

  form {
    input,
    textarea {
      margin: 20px auto 0;
      padding: 14px 24px;
      width: 100%;
      background: $defaultWhite;
      height: 45px;
      border: none;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 10px;
      color: $titleBlack;
      font-size: 14px;
      line-height: 16px;
      box-shadow: 0 15px 30px $slideBoxShadow;
      resize: none;

      &::placeholder {
        display: inline;
        vertical-align: top;
      }

      &:focus,
      :active {
        outline: none;
        padding: 12px 22px;
        border: 2px solid $borderBlue;
      }
    }
  }
}
